export default {
    toolbar: {
        undo: 'Undo',
        redo: 'Redo',
        paintFormat: 'Paint format',
        currencyFormat: 'Format as currency',
        percentageFormat: 'Format as percent',
        numberDecrease: 'Decrease decimal places',
        numberIncrease: 'Increase decimal places',
        moreFormats:'More formats',
        font: 'Font',
        fontSize: 'Font size',
        bold: 'Bold (Ctrl+B)',
        italic : 'Italic (Ctrl+I)',
        strikethrough: 'Strikethrough (Alt+Shift+5)',
        textColor: 'Text color',
        chooseColor: 'choose color',
        resetColor: 'Reset',
        customColor: 'CUSTOM',
        alternatingColors: 'Alternating colors',
        confirmColor: 'OK',
        cancelColor: 'Cancel',
        collapse: 'Collapse',
        fillColor: 'Fill color',
        border: 'Border',
        borderStyle: 'Border style',
        mergeCell: 'Merge cells',
        chooseMergeType: 'Choose merge type',
        horizontalAlign: 'Horizontal align',
        verticalAlign: 'Vertical align',
        alignment: 'Alignment',
        textWrap: 'Text wrap',
        textWrapMode: 'Text wrap mode',
        textRotate: 'Text rotate',
        textRotateMode: 'Text rotate mode',
        freezeTopRow: 'Freeze top row',
        sortAndFilter: 'Sort and filter',
        findAndReplace: 'Find and replace',
        sum: 'SUM',
        autoSum: 'Auto SUM',
        moreFunction: 'More function',
        conditionalFormat: 'Conditional format',
        postil: 'Comment',
        pivotTable: 'PivotTable',
        chart: 'Chart',
        screenshot: 'Screenshot',
        splitColumn: 'Split text',
        insertImage: 'Insert image',

        clearText:"Clear color",
        noColorSelectedText:"No color is selected",

        toolMore:"More",
        toolLess:"Less",
        toolClose:"Close",
        toolMoreTip:"More features",
    },
    alternatingColors:{
        applyRange: 'Apply to range',
        selectRange: 'Select a data range',
        header: 'Header',
        footer: 'Footer',

        errorInfo:"Cannot perform this operation on multiple selection areas, please select a single area and try again",
        textTitle:"Format style",
        custom:"CUSTOM",
        close:"close",
        selectionTextColor:"Click to select text color",
        selectionCellColor:"Click to select cell color",
        removeColor:"Remove alternating colors",
        colorShow:"color",
        currentColor:"Current",

        tipSelectRange:"Please select the range of alternating colors",
        errorNoRange:"No range is selected",
        errorExistColors:"Alternating colors already exist and cannot be edited",
    },
    button: {
        confirm: 'OK',
        cancel: 'Cancel',
        close:"Close"
    },
    paint:{
        start: 'Paint format start',
        end: 'ESC',

        tipSelectRange:"Please select the range to be copied",
        tipNotMulti:"Cannot perform this operation on multiple selection range",
    },
    format:{
        moreCurrency: 'More currency formats',
        moreDateTime: 'More date and time formats',
        moreNumber: 'More number formats',

        titleCurrency: 'Currency formats',
        decimalPlaces: 'Decimal places',
        titleDateTime: 'Date and time formats',
        titleNumber: 'Number formats'
    },
    info:{
        detailUpdate: 'New opened',
        detailSave: 'Local cache restored',
        row: '',
        column: '',
        loading:"Loading",

        copy:"Copy",
        return:"Exit",
        rename:"Rename",
        tips:"WorkBook rename",
        noName:"Untitled spreadsheet",
        wait:"waiting for update",

        add:"Add",
        addLast:"more rows at bottom",
        backTop:"Back to the top",
        pageInfo:'Total ${total}，${totalPage} page，current ${currentPage}',
        nextPage:"Next",

        tipInputNumber:"Please enter the number",
        tipInputNumberLimit:"The increase range is limited to 1-100",

        tipRowHeightLimit:"The value must be between 0 ~ 255",
        pageInfoFull:'Total ${total}，${totalPage} page，All data displayed',
    },
    currencyDetail:{
        RMB:'RMB',
        USdollar:'US dollar',
        EUR:'EUR',
        GBP:'GBP',
        HK:'HK',
        JPY:'JPY',
        AlbanianLek:'Albanian Lek',
        AlgerianDinar:'Algerian Dinar',
        Afghani:'Afghani',
        ArgentinePeso:'Argentine Peso',
        UnitedArabEmiratesDirham:'United Arab Emirates Dirham',
        ArubanFlorin:'Aruban Florin',
        OmaniRial:'Omani Rial',
        Azerbaijanimanat:'Azerbaijani manat',
        EgyptianPound:'Egyptian Pound',
        EthiopianBirr:'Ethiopian Birr',
        AngolaKwanza:'Angola Kwanza',
        AustralianDollar:'Australian Dollar',
        Patacas:'Patacas',
        BarbadosDollar:'Barbados Dollar',
        PapuaNewGuineaKina:'Papua New Guinea Kina',
        BahamianDollar:'Bahamian Dollar',
        PakistanRupee:'Pakistan Rupee',
        ParaguayanGuarani:'Paraguayan Guarani',
        BahrainiDinar:'Bahraini Dinar',
        PanamanianBalboa:'Panamanian Balboa',
        Brazilianreal:'Brazilian real',
        Belarusianruble:'Belarusian ruble',
        BermudianDollar:'Bermudian Dollar',
        BulgarianLev:'Bulgarian Lev',
        IcelandKrona:'Iceland Krona',
        BosniaHerzegovinaConvertibleMark:'Bosnia-Herzegovina Convertible Mark',
        PolishZloty:'Polish Zloty',
        Boliviano:'Boliviano',
        BelizeDollar:'Belize Dollar',
        BotswanaPula:'Botswana Pula',
        NotDannuzhamu:'Not Dannuzhamu',
        BurundiFranc:'Burundi Franc',
        NorthKoreanWon:'North Korean Won',
        DanishKrone:'Danish Krone',
        EastCaribbeanDollar:'East Caribbean Dollar',
        DominicaPeso:'Dominica Peso',
        RussianRuble:'Russian Ruble',
        EritreanNakfa:'Eritrean Nakfa',
        CFAfranc:'CFA franc',
        PhilippinePeso:'Philippine Peso',
        FijiDollar:'Fiji Dollar',
        CapeVerdeEscudo:'Cape Verde Escudo',
        FalklandIslandsPound:'Falkland Islands Pound',
        GambianDalasi:'Gambian Dalasi',
        Congolesefranc:'Congolese franc',
        ColombianPeso:'Colombian Peso',
        CostaRicanColon:'Costa Rican Colon',
        CubanPeso:'Cuban Peso',
        Cubanconvertiblepeso:'Cuban convertible peso',
        GuyanaDollar:'Guyana Dollar',
        KazakhstanTenge:'Kazakhstan Tenge',
        Haitiangourde:'Haitian gourde',
        won:'won',
        NetherlandsAntillesGuilder:'Netherlands Antilles Guilder',
        Honduraslempiras:'Honduras lempiras',
        DjiboutiFranc:'Djibouti Franc',
        KyrgyzstanSom:'Kyrgyzstan Som',
        GuineaFranc:'Guinea Franc',
        CanadianDollar:'Canadian Dollar',
        GhanaianCedi:'Ghanaian Cedi',
        Cambodianriel:'Cambodian riel',
        CzechKoruna:'Czech Koruna',
        ZimbabweDollar:'Zimbabwe Dollar',
        QatariRiyal:'Qatari Riyal',
        CaymanIslandsDollar:'Cayman Islands Dollar',
        Comorianfranc:'Comorian franc',
        KuwaitiDinar:'Kuwaiti Dinar',
        CroatianKuna:'Croatian Kuna',
        KenyanShilling:'Kenyan Shilling',
        LesothoLoti:'Lesotho Loti',
        LaoKip:'Lao Kip',
        LebanesePound:'Lebanese Pound',
        Lithuanianlitas:'Lithuanian litas',
        LibyanDinar:'Libyan Dinar',
        LiberianDollar:'Liberian Dollar',
        RwandaFranc:'Rwanda Franc',
        RomanianLeu:'Romanian Leu',
        MalagasyAriary:'Malagasy Ariary',
        MaldivianRufiyaa:'Maldivian Rufiyaa',
        MalawiKwacha:'Malawi Kwacha',
        MalaysianRinggit:'Malaysian Ringgit',
        MacedoniawearingDinar:'Macedonia wearing Dinar',
        MauritiusRupee:'Mauritius Rupee',
        MauritanianOuguiya:'Mauritanian Ouguiya',
        MongolianTugrik:'Mongolian Tugrik',
        BangladeshiTaka:'Bangladeshi Taka',
        PeruvianNuevoSol:'Peruvian Nuevo Sol',
        MyanmarKyat:'Myanmar Kyat',
        MoldovanLeu:'Moldovan Leu',
        MoroccanDirham:'Moroccan Dirham',
        MozambiqueMetical:'Mozambique Metical',
        MexicanPeso:'Mexican Peso',
        NamibianDollar:'Namibian Dollar',
        SouthAfricanRand:'South African Rand',
        SouthSudanesePound:'South Sudanese Pound',
        NicaraguaCordoba:'Nicaragua Cordoba',
        NepaleseRupee:'Nepalese Rupee',
        NigerianNaira:'Nigerian Naira',
        NorwegianKrone:'Norwegian Krone',
        GeorgianLari:'Georgian Lari',
        RMBOffshore:'RMB (Offshore)',
        SwedishKrona:'Swedish Krona',
        SwissFranc:'Swiss Franc',
        SerbianDinar:'Serbian Dinar',
        SierraLeone:'Sierra Leone',
        SeychellesRupee:'Seychelles Rupee',
        SaudiRiyal:'Saudi Riyal',
        SaoTomeDobra:'Sao Tome Dobra',
        SaintHelenapound:'Saint Helena pound',
        SriLankaRupee:'Sri Lanka Rupee',
        SwazilandLilangeni:'Swaziland Lilangeni',
        SudanesePound:'Sudanese Pound',
        Surinamesedollar:'Surinamese dollar',
        SolomonIslandsDollar:'Solomon Islands Dollar',
        SomaliShilling:'Somali Shilling',
        TajikistanSomoni:'Tajikistan Somoni',
        PacificFranc:'Pacific Franc',
        ThaiBaht:'Thai Baht',
        TanzanianShilling:'Tanzanian Shilling',
        TonganPaanga:"Tongan Pa'anga",
        TrinidadandTobagoDollar:'Trinidad and Tobago Dollar',
        TunisianDinar:'Tunisian Dinar',
        TurkishLira:'Turkish Lira',
        VanuatuVatu:'Vanuatu Vatu',
        GuatemalanQuetzal:'Guatemalan Quetzal',
        CommissionBolivar:'Commission Bolivar',
        BruneiDollar:'Brunei Dollar',
        UgandanShilling:'Ugandan Shilling',
        UkrainianHryvnia:'Ukrainian Hryvnia',
        UruguayanPeso:'Uruguayan Peso',
        Uzbekistansom:'Uzbekistan som',
        WesternSamoaTala:'Western Samoa Tala',
        SingaporeDollar:'Singapore Dollar',
        NT:'NT',
        NewZealandDollar:'New Zealand Dollar',
        HungarianForint:'Hungarian Forint',
        SyrianPound:'Syrian Pound',
        JamaicanDollar:'Jamaican Dollar',
        ArmenianDram:'Armenian Dram',
        YemeniRial:'Yemeni Rial',
        IraqiDinar:'Iraqi Dinar',
        IranianRial:'Iranian Rial',
        NewIsraeliShekel:'New Israeli Shekel',
        IndianRupee:'Indian Rupee',
        IndonesianRupiah:'Indonesian Rupiah',
        JordanianDinar:'Jordanian Dinar',
        VND:'VND',
        ZambianKwacha:'Zambian Kwacha',
        GibraltarPound:'Gibraltar Pound',
        ChileanPeso:'Chilean Peso',
        CFAFrancBEAC:'CFA Franc BEAC'
    },
    defaultFmt:[
        { "text": 'Automatic', "value": "General", "example": "" },
        { "text": 'Plain text', "value": "@", "example": "" },
        { "text": "", "value": "split", "example": "" },
        { "text": 'Number', "value": "##0.00", "example": "1000.12" },
        { "text": 'Percent', "value": "#0.00%", "example": "12.21%" },
        { "text": 'Scientific', "value": "0.00E+00", "example": "1.01E+5" },
        { "text": "", "value": "split", "example": "" },
        { "text": 'Accounting', "value": "¥(0.00)", "example": "¥(1200.09)" },


        { "text": 'Currency', "value": "¥0.00", "example": "¥1200.09" },


        { "text": "", "value": "split", "example": "" },
        { "text": 'Date', "value": "yyyy-MM-dd", "example": "2017-11-29" },
        { "text": 'Time', "value": "hh:mm AM/PM", "example": "3:00 PM" },
        { "text": 'Time 24H', "value": "hh:mm", "example": "15:00" },
        { "text": 'Date time', "value": "yyyy-MM-dd hh:mm AM/PM", "example": "2017-11-29 3:00 PM" },
        { "text": 'Date time 24 H', "value": "yyyy-MM-dd hh:mm", "example": "2017-11-29 15:00" },
        { "text": "", "value": "split", "example": "" },
        { "text": 'Custom formats', "value": "fmtOtherSelf", "example": "more" }
    ],
    dateFmtList:[
        {
            "name": "1930-08-05",
            "value": "yyyy-MM-dd"
        },
        {
            "name": "1930/8/5",
            "value": "yyyy/MM/dd"
        },
        {
            "name": "08-05",
            "value": "MM-dd"
        },
        {
            "name": "8-5",
            "value": "M-d"
        },
        {
            "name": "13:30:30",
            "value": "h:mm:ss"
        },
        {
            "name": "13:30",
            "value": "h:mm"
        },
        {
            "name": "PM 01:30",
            "value": 'AM/PM hh:mm'
        },
        {
            "name": "PM 1:30",
            "value": 'AM/PM h:mm'
        },
        {
            "name": "PM 1:30:30",
            "value": 'AM/PM h:mm:ss'
        },
        {
            "name": "08-05 PM 01:30",
            "value": "MM-dd AM/PM hh:mm"
        }
    ],
    fontFamily:{
        MicrosoftYaHei:"YaHei",
    },
    fontarray: ["Times New Roman","Arial","Tahoma","Verdana"],
    fontjson: {"times new roman":0,"arial":1,"tahoma":2,"verdana":3},
    border:{
        borderTop:'borderTop',
        borderBottom:'borderBottom',
        borderLeft:'borderLeft',
        borderRight:'borderRight',
        borderNone:'borderNone',
        borderAll:'borderAll',
        borderOutside:'borderOutside',
        borderInside:'borderInside',
        borderHorizontal:'borderHorizontal',
        borderVertical:'borderVertical',
        borderColor:'borderColor',
        borderSize:'borderSize',
    },
    merge:{
        mergeAll:"Merge all",
        mergeV:"Vertically",
        mergeH:"Horizentally",
        mergeCancel:"Unmerge",
        overlappingError:"Cannot merge overlapping areas",
        partiallyError:"Cannot perform this operation on partially merged cells",
    },
    align:{
        left:"left",
        center:"center",
        right:"right",

        top:"Top",
        middle:"Middle",
        bottom:"Bottom",
    },
    textWrap:{
        "overflow":"Overflow",
        "wrap":"Wrap",
        "clip":"Clip",
    },
    rotation:{
        "none":"None",
        "angleup":"Tilt Up",
        "angledown":"Tilt down",
        "vertical":"Stack Vertically",
        "rotationUp":"Rotate Up",
        "rotationDown":"Rotate down"
    },
    freezen:{
        default:"Freezen",
        freezenRow:"First Row",
        freezenColumn:"First column",
        freezenRC:"Both",
        freezenRowRange:"Freezen row range",
        freezenColumnRange:"Freezen column range",
        freezenRCRange:"Freezen both range",
        freezenCancel:"Cancel",

        noSeletionError:"No Range to be selected",
    },
    sort:{
        "asc":"Ascending ",
        "desc":"Descending ",
        "custom":"Custom sort",

        "hasTitle":"Data has header row",
        "sortBy":"Sort by",
        "addOthers":"Add another sort column",
        "close":"close",
        "confirm":"sort",

        "columnOperation":"Column",
        "secondaryTitle":"then by",

        "sortTitle":"Sort range",

        "sortRangeTitle":"Sort range from",
        "sortRangeTitleTo":"to",


        "noRangeError":"Cannot perform this operation on multiple selection areas, please select a single range and try again",
        "mergeError":"There are merged cells in the selection, this operation cannot be performed!",

    },
    filter:{
       "filter":"create filter", 
       "clearFilter":"Cancel filter", 

       sortByAsc:"Sort A-Z",
       sortByDesc:"Sort Z-A",
       filterByColor:"Filter by color",
       filterByCondition:"Filter by condition",
       filterByValues:"Filter by values",

       filiterInputNone:"None",

       filiterInputTip:"Enter filter value",
       filiterRangeStartTip:"Value for formula",
       filiterRangeEndTip:"Value for formula",

       filterValueByAllBtn:"Check all",
       filterValueByClearBtn:"Clear",
       filterValueByInverseBtn:"Inverse",
       filterValueByTip:"filter By Values",
       filterConform:"Confirm",
       filterCancel:"Cancel",
       clearFilter:"Clear filter",

       conditionNone:"None",
        conditionCellIsNull:"Is empty",
        conditionCellNotNull:"Is not empty",
        conditionCellTextContain:"Text contains",
        conditionCellTextNotContain:"Text does not contain",
        conditionCellTextStart:"Text starts with",
        conditionCellTextEnd:"Text ends with",
        conditionCellTextEqual:"Text is exactly",
        conditionCellDateEqual:"Date is",
        conditionCellDateBefore:"Date is before",
        conditionCellDateAfter:"Date is after",
        conditionCellGreater:"Greater than",
        conditionCellGreaterEqual:"Greater than or equal to",
        conditionCellLess:"Less than",
        conditionCellLessEqual:"Less than or equal to",
        conditionCellEqual:"Is equal to",
        conditionCellNotEqual:"Is not equal to",
        conditionCellBetween:"Is between",
        conditionCellNotBetween:"Is not between",

        filiterMoreDataTip:"Big amount of data! please wait",
        filiterMonthText:"Month",
        filiterYearText:"Year",
        filiterByColorTip:"Filter by cell color",
        filiterByTextColorTip:"Filter by font color",
        filterContainerOneColorTip:"This column contains only one color",
        filterDateFormatTip:"Date format",

        valueBlank:"(Null)",
        mergeError:"There are merged cells in the filter selection, this operation cannot be performed!",
    },
    rightclick: {
        copy: 'Copy',
        copyAs: 'Copy as',
        paste: 'Paste',
        insert: 'Insert',
        delete: 'Delete',
        deleteCell: 'Delete cell',
        deleteSelected: 'Delete selected ',
        hide: 'Hide',
        hideSelected: 'Hide selected ',
        showHide: 'Show hide ',
        to: 'Towards',
        left: 'Left',
        right: 'Right',
        top: 'Top',
        bottom: 'Bottom',
        moveLeft: 'Move left',
        moveUp: 'Move up',
        add: 'Add',
        row: 'Row',
        column: 'Column',
        width: 'Width',
        height: 'Height',
        number: 'Number',
        confirm: 'Confirm',
        orderAZ: 'A-Z order',
        orderZA: 'Z-A order',
        clearContent: 'Clear content',
        matrix: 'Matrix operation',
        sortSelection: 'Sort',
        filterSelection: 'Filter',
        chartGeneration: 'Create chart',
        firstLineTitle: 'First line title',
        untitled: 'Untitled',
        array1: 'One-dimensional array',
        array2: 'Two-dimensional array',
        array3: 'Multidimensional Arrays',
        diagonal: 'Diagonal',
        antiDiagonal: 'Anti-diagonal',
        diagonalOffset: 'Diagonal offset',
        offset: 'Offset',
        boolean: 'Boolean',
        flip: 'Flip',
        upAndDown: 'Up and down',
        leftAndRight: 'Left and right',
        clockwise: 'Clockwise',
        counterclockwise: 'Counterclockwise',
        transpose: 'Transpose',
        matrixCalculation: 'Matrix calculation',
        plus: 'Plus',
        minus: 'Minus',
        multiply: 'Multiply',
        divided: 'Divided',
        power: 'Power',
        root: 'Root',
        log: 'Log',
        delete0: 'Delete 0 values at both ends',
        removeDuplicate: 'Remove duplicate values',
        byRow: 'By row',
        byCol: 'By column',
        generateNewMatrix: 'Generate new matrix',
        
    },
    comment:{
        "insert":"Insert",
        "edit":"Edit",
        "delete":"Delete",
        "showOne":"Show/Hide",
        "showAll":"Show/Hide All"
    },
    screenshot:{
        screenshotTipNoSelection:"Please select the scope of the screenshot",
        screenshotTipTitle:"Warning！",
        screenshotTipHasMerge:"This operation cannot be performed on merged cells",
        screenshotTipHasMulti:"This operation cannot be performed on multiple selection regions",
        screenshotTipSuccess:"Successful",
        screenshotImageName:"Screenshot",

        downLoadClose:"Close",
        downLoadCopy:"Copy to clipboard",
        downLoadBtn:"DownLoad",
        browserNotTip:"not supported by IE browser!",
        rightclickTip:"Please right click \"copy\" on the picture",
        successTip:"Successfully (if pasting fails, please right-click on the image to \"copy image\")",
    },
    splitText:{
        splitDelimiters:"Delimiters",
        splitOther:"Other",
        splitContinueSymbol:"Consecutive separators are treated as a single",
        splitDataPreview:"Preview",
        splitTextTitle:"Split text",
        splitConfirmToExe:"There is already data here, do you want to replace it?",

        tipNoMulti:"Cannot perform this operation on multiple selection areas, please select a single area and try again",
        tipNoMultiColumn:"Only one column of data can be converted at a time. The selected area can have multiple rows but not multiple columns. Please try again after selecting a single column range",
    },
    imageText:{
        imageSetting: 'Image setting',
        close: 'Close',
        conventional: 'Conventional',
        moveCell1: 'Move and resize cells',
        moveCell2: 'Move and do not resize the cell',
        moveCell3: 'Do not move and resize the cell',
        fixedPos: 'Fixed position',
        border: 'Border',
        width: 'Width',
        radius: 'Radius',
        style: 'Style',
        solid: 'Solid',
        dashed: 'Dashed',
        dotted: 'Dotted',
        double: 'Double',
        color: 'Color',
    },
    punctuation:{
        "tab":"Tab",
        "semicolon":"semicolon",
        "comma":"comma",
        "space":"space",
        
    },

    findAndReplace:{
        find:"Find",
        replace:"Replace",
        goto:"Goto",
        location:"Location",
        formula:"Formula",
        date:"Date",
        number:"Number",
        string:"String",
        error:"Error",
        condition:"Condition",
        rowSpan:"Row span",
        columnSpan:"Column span",
        locationExample:"Location",
        lessTwoRowTip:"Please select at least two rows",
        lessTwoColumnTip:"Please select at least two columns",

        findTextbox:"Find Content",
        replaceTextbox:"Replace Content",

        regexTextbox:"Regular Expression",
        wholeTextbox:"Whole word",
        distinguishTextbox:"Case sensitive",

        allReplaceBtn:"Replace All",
        replaceBtn:"Replace",
        allFindBtn:"Find All",
        findBtn:"Find next",

        noFindTip:"The content was not found",

        searchTargetSheet:"Sheet",
        searchTargetCell:"Cell",
        searchTargetValue:"Value",

        searchInputTip:"Please enter the search content",

        noReplceTip:"There is nothing to replace",
        noMatchTip:"No match found",

        successTip:"${xlength} items found",

        locationConstant:"Constant",
        locationFormula:"Formula",
        locationDate:"Date",
        locationDigital:"number",
        locationString:"String",
        locationBool:"Logical",
        locationError:"Error",
        locationNull:"Null",
        locationCondition:"Conditional format",
        locationRowSpan:"Row span",
        locationColumnSpan:"Column span",

        locationTiplessTwoRow:"Please select at least two Row",
        locationTiplessTwoColumn:"Please select at least two columns",
        locationTipNotFindCell:"Cell not found"
    
    },
    sheetconfig: {
        delete: 'Delete',
        copy: 'Copy',
        rename: 'Rename',
        changeColor: 'Change color',
        hide: 'Hide',
        unhide: 'Unhide',
        moveLeft: 'Move left',
        moveRight: 'Move right',
        resetColor: 'Reset color',
        cancelText: 'Cancel',
        chooseText: 'Confirm color',

        tipNameRepeat:"The name of the tab page cannot be repeated! Please revise",
        noMoreSheet:"The workbook contains at least one visual worksheet. To delete the selected worksheet, please insert a new worksheet or show a hidden worksheet.",
        confirmDelete:"Are you sure to delete",
        redoDelete:"Can be undo by Ctrl+Z",
        noHide:"Can't hide, at least keep one sheet tag",
        chartEditNoOpt:"This operation is not allowed in chart editing mode!",
    },
    conditionformat: {
        conditionformat_greaterThan: 'Conditionformat-GreaterThan',
        conditionformat_greaterThan_title: 'Format cells greater than',
        conditionformat_lessThan: 'Conditionformat-LessThan',
        conditionformat_lessThan_title: 'Format cells smaller than',
        conditionformat_betweenness: 'Conditionformat-Betweenness',
        conditionformat_betweenness_title: 'Format cells with values between',
        conditionformat_equal: 'Conditionformat-Equal',
        conditionformat_equal_title: 'Format cells equal to',
        conditionformat_textContains: 'Conditionformat-TextContains',
        conditionformat_textContains_title: 'Format cells containing the following text',
        conditionformat_occurrenceDate: 'Conditionformat-OccurrenceDate',
        conditionformat_occurrenceDate_title: 'Format cells containing the following dates',
        conditionformat_duplicateValue: 'Conditionformat-DuplicateValue',
        conditionformat_duplicateValue_title: 'Format cells containing the following types of values',
        conditionformat_top10: 'Conditionformat-Top10',
        conditionformat_top10_percent: 'Conditionformat-Top10%',
        conditionformat_top10_title: 'Format the cells with the highest value',
        conditionformat_last10: 'Conditionformat-Last10',
        conditionformat_last10_percent: 'Conditionformat-Last10%',
        conditionformat_last10_title: 'Format the cells with the smallest value',
        conditionformat_AboveAverage: 'Conditionformat-AboveAverage',
        conditionformat_AboveAverage_title: 'Format cells above average',
        conditionformat_SubAverage: 'Conditionformat-SubAverage',
        conditionformat_SubAverage_title: 'Format cells below average',
        rule: 'Rule',
        newRule: 'New rule',
        editRule: 'Edit rule',
        deleteRule: 'Delete rule',
        deleteCellRule: 'Delete cell rule',
        deleteSheetRule: 'Delete sheet rule',
        manageRules: 'Management rules',
        showRules: 'Show its formatting rules',
        highlightCellRules: 'Highlight cell rules',
        itemSelectionRules: 'Item selection rules',
        conditionformatManageRules: 'Conditional Formatting Rule Manager',
        format: 'Format',
        setFormat: 'Set format',
        setAs: 'Set as',
        setAsByArea: 'For the selected area, set to',
        applyRange: 'Apply range',
        selectRange: 'Select application range',
        selectRange_percent: 'Percentage of selected range',
        selectRange_average: 'Average value of selected range',
        selectRange_value: 'Value in the selected range',
        pleaseSelectRange: 'Please select application range',
        selectDataRange: 'Select data range',
        selectCell: 'select cell',
        pleaseSelectCell: 'Please select cell',
        pleaseSelectADate: 'Please select a date',
        pleaseEnterInteger: 'Please enter an integer between 1 and 1000',
        onlySingleCell: 'Only a single cell can be referenced',
        conditionValueCanOnly: 'The condition value can only be a number or a single cell',
        ruleTypeItem1: 'Format all cells based on their respective values',
        ruleTypeItem2: 'Only format cells that contain',
        ruleTypeItem2_title: 'Only for cells that meet the following conditions',
        ruleTypeItem3: 'Format only the top or bottom numbers',
        ruleTypeItem3_title: 'Is the value in the following ranking',
        ruleTypeItem4: 'Format only values above or below the average',
        ruleTypeItem4_title: 'Is a value that satisfies the following conditions',
        ruleTypeItem5: 'Format only unique or repeated values',
        textColor: 'Text color',
        cellColor: 'Cell color',
        confirm: 'Confirm',
        confirmColor: 'Confirm color',
        cancel: 'Cancel',
        close: 'Close',
        clearColorSelect: 'Clear color select',
        sheet: 'Sheet',
        currentSheet: 'CurrentSheet',
        dataBar: 'dataBar',
        dataBarColor: 'dataBar color',
        gradientDataBar_1: 'Blue-white gradient data bar',
        gradientDataBar_2: 'Green-white gradient data bar',
        gradientDataBar_3: 'Red-white gradient data bar',
        gradientDataBar_4: 'Orange-white gradient stripes',
        gradientDataBar_5: 'Light blue-white gradient stripes',
        gradientDataBar_6: 'Purple-white gradient data bar',
        solidColorDataBar_1: 'Blue data bar',
        solidColorDataBar_2: 'Green data bar',
        solidColorDataBar_3: 'Red data bar',
        solidColorDataBar_4: 'Orange data bar',
        solidColorDataBar_5: 'Light blue data bar',
        solidColorDataBar_6: 'Purple data bar',
        colorGradation: 'colorGradation',
        colorGradation_1: 'Green-yellow-red color gradation',
        colorGradation_2: 'Red-yellow-green color gradation',
        colorGradation_3: 'Green-white-red color gradation',
        colorGradation_4: 'Red-white-green color gradation',
        colorGradation_5: 'Blue-white-red color gradation',
        colorGradation_6: 'Red-white-blue color gradation',
        colorGradation_7: 'White-red color gradation',
        colorGradation_8: 'Red-white color gradation',
        colorGradation_9: 'Green-white color gradation',
        colorGradation_10: 'White-green color gradation',
        colorGradation_11: 'Green-yellow color gradation',
        colorGradation_12: 'Yellow-green color gradation',
        icons: 'icons',
        pleaseSelectIcon: 'Please click to select a group of icons:',
        cellValue: 'Cell value',
        specificText: 'Specific text',
        occurrence: 'Date',
        greaterThan: 'Greater than',
        lessThan: 'Less than',
        between: 'Between',
        equal: 'Equal',
        in: 'In',
        between2: '',
        contain: 'Contain',
        textContains: 'Text contains',
        duplicateValue: 'Duplicate value',
        uniqueValue: 'Unique value',
        top: 'Top',
        top10: 'Top10',
        top10_percent: 'Top10%',
        last: 'Last',
        last10: 'Last10',
        last10_percent: 'Last10%',
        oneself: '',
        above: 'Above',
        aboveAverage: 'Above average',
        below: 'Below',
        belowAverage: 'Below average',
        all: 'All',
        yesterday: 'YTD',
        today: 'Today',
        tomorrow: 'Tomorrow',
        lastWeek: 'Last week',
        thisWeek: 'This week',
        lastMonth: 'Last month',
        thisMonth: 'This month',
        lastYear: 'Last year',
        thisYear: 'This year',
        last7days: 'Last 7 days',
        last30days: 'Last 30 days',
        next7days: 'Next 7 days',
        next30days: 'Next 30 days',
        next60days: 'Next 60 days',
        chooseRuleType: 'Choose rule type',
        editRuleDescription: 'Edit rule description',
        newFormatRule: 'New format rule',
        editFormatRule: 'Edit format rule',
        formatStyle: 'Style',
        fillType: 'Fill',
        color: 'Color',
        twocolor: 'Two-color',
        tricolor: 'Tricolor',
        multicolor: 'Multi color',
        grayColor: 'Gray color',
        gradient: 'Gradient',
        solid: 'Solid',
        maxValue: 'Max value',
        medianValue: 'Median value',
        minValue: 'Min value',
        direction: 'Direction',
        threeWayArrow: 'Three-way arrow',
        fourWayArrow: 'Four-way arrow',
        fiveWayArrow: 'Five-way arrow',
        threeTriangles: 'Three triangles',
        shape: 'Shape',
        threeColorTrafficLight: 'Three-color traffic light',
        fourColorTrafficLight: 'Four-color traffic light',
        threeSigns: 'Three signs',
        greenRedBlackGradient: 'Green-red-black gradient',
        rimless: 'Rimless',
        bordered: 'Bordered',
        mark: 'Mark',
        threeSymbols: 'Three symbols',
        tricolorFlag: 'Tricolor flag',
        circled: 'Circled',
        noCircle: 'No circle',
        grade: 'Grade',
        grade4: '4 Grade',
        grade5: '5 Grade',
        threeStars: '3 Stars',
        fiveQuadrantDiagram: 'Five-quadrant diagram',
        fiveBoxes: '5 Boxes',
    },
    formula:{
        sum:"Sum",
        average:"Average",
        count:"Count",
        max:"Max",
        min:"Min",
        ifGenerate:"If formula generator",
        find:"Learn more",

        tipNotBelongToIf:"This cell function does not belong to the if formula!",
        tipSelectCell:"Please select the cell to insert the function",

        ifGenCompareValueTitle:"Comparison value",
        ifGenSelectCellTitle:"Click to select cell",
        ifGenRangeTitle:"Range",
        ifGenRangeTo:"to",
        ifGenRangeEvaluate:"Range evaluate",
        ifGenSelectRangeTitle:"Click to select range",
        ifGenCutWay:"Partition way",
        ifGenCutSame:"Same Partition value",
        ifGenCutNpiece:"Partition by N",
        ifGenCutCustom:"Custom",
        ifGenCutConfirm:"Confirm",

        ifGenTipSelectCell:"Select cells",
        ifGenTipSelectCellPlace:"Please select cells",

        ifGenTipSelectRange:"Select range",
        ifGenTipSelectRangePlace:"Please select range",

        ifGenTipNotNullValue:"The comparison value cannot be empty!",
        ifGenTipLableTitile:"Label",
        ifGenTipRangeNotforNull:"The range cannot be empty!",
        ifGenTipCutValueNotforNull:"The partition value cannot be empty!",
        ifGenTipNotGenCondition:"No conditions are available for generation!",
    },
    formulaMore:{
        valueTitle:"Value",
        tipSelectDataRange:"Select data range",
        tipDataRangeTile:"Data range",
        findFunctionTitle:"Search function",
        tipInputFunctionName:"Function name or brief description of function",

        "Array":"Array",
        "Database":"Database",
        "Date":"Date",
        "Engineering":"Engineering",
        "Filter":"Filter",
        "Financial":"Financial",
        "luckysheet":"Luckysheet",
        "other":"Other",
        "Logical":"Logical",
        "Lookup":"Lookup",
        "Math":"Math",
        "Operator":"Operator",
        "Parser":"Parser",
        "Statistical":"Statistical",
        "Text":"Text",
        "dataMining":"DataMining",

        "selectFunctionTitle":"Select a function",
        "calculationResult":"Result",

        tipSuccessText:"Success",
        tipParamErrorText:"Parameter type error",

        "helpClose":"Close",
        "helpCollapse":"Collapse",
        "helpExample":"Example",
        "helpAbstract":"Abstract",

        "execfunctionError":'Error in the formula',
        "execfunctionSelfError":'Formula cannot refer to its own cell',
        "execfunctionSelfErrorResult":'The formula cannot refer to its own cell, which will lead to inaccurate calculation results',

        "allowRepeatText":"Repeat",
        "allowOptionText":"Option",

        "selectCategory":"Or select a category",
    },
    drag:{
        noMerge:"Cannot perform this operation on merged cells",
        affectPivot:"This change cannot be made to the selected cell because it will affect the pivot table!",
        noMulti:"Cannot perform this operation on multiple selection areas, please select a single area",
        noPaste:"Unable to paste this content here, please select a cell in the paste area and try to paste again",
        noPartMerge:"Cannot perform this operation on partially merged cells",

        inputCorrect:"Please enter the correct value",
        notLessOne:"The number of rows and columns cannot be less than 1",
        offsetColumnLessZero:"The offset column cannot be negative!",
    },
    pivotTable:{
        title:"PivotTable",
        closePannel:"Close",
        editRange:"Range",
        tipPivotFieldSelected:"Select the fields",
        tipClearSelectedField:"Clear all fields",
        btnClearSelectedField:"Clear",
        btnFilter:"Filter",
        titleRow:"Row",
        titleColumn:"Column",
        titleValue:"Value",
        tipShowColumn:"Statistics fields are displayed as columns",
        tipShowRow:"Statistics fields are displayed as rows",

        titleSelectionDataRange:"Select range",
        titleDataRange:"Data range",

        valueSum:"SUM",

        valueStatisticsSUM:"Sum",
        valueStatisticsCOUNT:"Count",
        valueStatisticsCOUNTA:"CountA",
        valueStatisticsCOUNTUNIQUE:"CountUnique",
        valueStatisticsAVERAGE:"Average",
        valueStatisticsMAX:"Max",
        valueStatisticsMIN:"Min",
        valueStatisticsMEDIAN:"Median",
        valueStatisticsPRODUCT:"Product",
        valueStatisticsSTDEV:"Stdev",

        valueStatisticsSTDEVP:"Stdevp",
        valueStatisticslet:"Var",
        valueStatisticsVARP:"VarP",

        errorNotAllowEdit:"This operation is prohibited in non-editing mode!",
        errorNotAllowMulti:"Cannot perform this operation on multiple selection areas, please select a single range and try again",
        errorSelectRange:"Please select the range of the new pivot table",
        errorIsDamage:"The source data of this pivot table is corrupted!",
        errorNotAllowPivotData:"Cannot select pivot table as source data!",
        errorSelectionRange:"Selection failed, wrong input range!",
        errorIncreaseRange:"Please expand the selected range!",

        titleAddColumn:"Add column to pivot table",
        titleMoveColumn:"Move the column to the white box below",
        titleClearColumnFilter:"Clear the filter for this column",
        titleFilterColumn:"Filter",

        titleSort:"Sort",
        titleNoSort:"No sort",
        titleSortAsc:"ASC",
        titleSortDesc:"DESC",
        titleSortBy:"Sort by",
        titleShowSum:"Show total",
        titleStasticTrue:"Yes",
        titleStasticFalse:"No",
    },
    dropCell:{
        copyCell:"Copy",
        sequence:"Sequence",
        onlyFormat:"Only format",
        noFormat:"Not format",
        day:"Day",
        workDay:"WorkDay",
        month:"Month",
        year:"Year",
        chineseNumber:"Chinese numbers",
    }
  };
  